/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { memo, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import {
    PaginationState,
    ExpandedState,
    useReactTable,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    getPaginationRowModel,
    flexRender,
    FilterFn
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';

import { Stack } from '@mui/system';
import {
    StyledTableCell,
    StyledTableRow,
    TableHeaderRowContainer,
    TableSortingUpAndDownIconContainer,
    TableSortingIcon,
    StyledTableContainer,
    FilterContainer,
    PaginationLayout,
    SearchLayout,
    AddClientButtonLayout,
    NoResultsFoundContainer,
    PortfolioLastUpdated
} from './styles';
import Pagination from '../../molecules/pagination/pagination-with-row-selection';
import ButtonComponent from '../../atoms/button';
import FilterCheckbox from './filterGroupByCheckbox';
import { ReactComponent as ArrowUp } from './assets/arrow-up.svg';
import { ReactComponent as ArrowDown } from './assets/arrow-down.svg';
import { calculatePaginationEntries } from '../../../utils';
import DebouncedInput from './debouncedInput';
import { NO_RESULTS_FOUND } from '../../../constants';
import { Customtooltip, ToolTipSvg } from '../../atoms/goal-purpose-slider';

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
        itemRank
    });

    console.log(row, columnId, value, itemRank.passed);
    return itemRank.passed;
};

function DataTableOrganism({
    handleAddClientClick,
    data,
    columnVisibility,
    searchPlaceHolder,
    columns,
    hideExtraText,
    addButtonText
}: // searchPorps,
any) {
    const { t } = useTranslation();

    const SEARCH_PLACEHOLDER_TEXT = searchPlaceHolder || t('TEXT_SEARCH_FOR_CLIENT');

    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [expanded, setExpanded] = React.useState<ExpandedState>({});
    const [globalFilter, setGlobalFilter] = React.useState('');
    const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15
    });

    const [paginationEntries, setPaginationEntries] = React.useState<{
        from?: number;
        to?: number;
        lastPage?: number;
    }>({});

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize
        }),
        [pageIndex, pageSize]
    );

    useEffect(() => {
        const result: any = calculatePaginationEntries(
            data?.length,
            table.getState().pagination.pageIndex + 1,
            pageSize
        );
        setPaginationEntries(result);
    }, [pageIndex, data, pageSize]);

    const table = useReactTable({
        data,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter
        },
        state: {
            columnVisibility,
            expanded,
            sorting,
            globalFilter,
            pagination
        },
        filterFromLeafRows: true,
        onPaginationChange: setPagination,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getPaginationRowModel: getPaginationRowModel(),
        paginateExpandedRows: false,
        onSortingChange: setSorting,
        getFilteredRowModel: getFilteredRowModel(),
        autoResetExpanded: false,
        onExpandedChange: setExpanded,
        getSubRows: (row) => row.subRows,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: true
    });

    const paginationProps = {
        totalCount: data?.length,
        pageEntries: t('TEXT_PAGINATION_FROM_TO', {
            FROM: paginationEntries.from,
            TO: paginationEntries.to
        }),
        nextPage: () => table.nextPage(),
        previousPage: () => table.previousPage(),
        canPreviousPage: !table.getCanPreviousPage(),
        canNextPage: !table.getCanNextPage()
    };

    return (
        <StyledTableContainer>
            <FilterContainer container>
                <Grid item xs={12} sm={12} md={8} lg={8} display="flex">
                    <SearchLayout item xs={12} sm={12} md={3} lg={3}>
                        <DebouncedInput
                            value={globalFilter ?? ''}
                            onChange={(value) => {
                                if (value) {
                                    table.toggleAllRowsExpanded(true);
                                } else {
                                    table.toggleAllRowsExpanded(false);
                                }
                                setGlobalFilter(String(value));
                            }}
                            placeholder={SEARCH_PLACEHOLDER_TEXT}
                        />
                        {/* <SearchBar {...searchPorps} /> */}
                    </SearchLayout>
                    <AddClientButtonLayout item xs={12} sm={12} md={5} lg={5}>
                        <ButtonComponent
                            variant="contained"
                            onClick={() => handleAddClientClick()}
                            data-testid="add-client-button"
                        >
                            {addButtonText || t('BUTTON_ADD_NEW_CLIENT')}
                        </ButtonComponent>
                    </AddClientButtonLayout>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={7}
                        sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}
                    >
                        <FilterCheckbox />
                    </Grid>
                </Grid>
                {!hideExtraText && (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        display="flex"
                        sx={{
                            justifyContent: { xs: 'start', sm: 'start', md: 'end', lg: 'end' },
                            alignItems: 'center'
                        }}
                    >
                        <PortfolioLastUpdated variant="body1" tabIndex={0} data-testid="portfolio-last-updated">
                            {''}
                        </PortfolioLastUpdated>
                    </Grid>
                )}
            </FilterContainer>
            {!isEmpty(table.getRowModel().rows) ? (
                <>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{
                                '& .MuiTableRow-root:hover': {
                                    backgroundColor: 'table.row.hover'
                                }
                            }}
                            stickyHeader
                            aria-label="caption table"
                            data-testid="table"
                        >
                            <caption tabIndex={0} style={{ display: 'none' }}>
                                {t('DESCRIPTION_TABLE')}
                            </caption>
                            <TableHead>
                                {table
                                    .getHeaderGroups()
                                    .map((headerGroup: { id: React.Key | null | undefined; headers: any[] }) => (
                                        <TableRow key={headerGroup.id}>
                                            {headerGroup.headers.map(
                                                (header: {
                                                    id: React.Key | null | undefined;
                                                    colSpan: number | undefined;
                                                    isPlaceholder: any;
                                                    column: {
                                                        getCanSort: () => string | number;
                                                        getToggleSortingHandler: () => any;
                                                        columnDef: {
                                                            header:
                                                                | string
                                                                | number
                                                                | boolean
                                                                | React.ReactElement<
                                                                      any,
                                                                      string | React.JSXElementConstructor<any>
                                                                  >
                                                                | React.ReactFragment
                                                                | React.ComponentType<any>
                                                                | null
                                                                | undefined;
                                                        };
                                                        getIsSorted: () => string;
                                                    };
                                                    getContext: () => any;
                                                }) => (
                                                    <StyledTableCell key={header.id} colSpan={header.colSpan}>
                                                        {header.isPlaceholder ? null : header.column.getCanSort() ? (
                                                            <TableHeaderRowContainer
                                                                center={+header.column.getCanSort()}
                                                                {...{
                                                                    onClick: header.column.getToggleSortingHandler()
                                                                }}
                                                            >
                                                                {flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                                {(header.id === 'goalAmount' ||
                                                                    header.id === 'goals' ||
                                                                    header.id === 'score') && (
                                                                    <Customtooltip
                                                                        title={
                                                                            header.id === 'goalAmount'
                                                                                ? t('TEXT_OVERALL_VALUE_TOOLTIP')
                                                                                : header.id === 'goals'
                                                                                ? t('TEXT_NO_OF_GOALS_TOOLTIP')
                                                                                : header.id === 'score'
                                                                                ? t('TEXT_SCORE_TOOLTIP')
                                                                                : ''
                                                                        }
                                                                        placement="top-start"
                                                                    >
                                                                        <ToolTipSvg
                                                                            style={{
                                                                                marginLeft: '10px'
                                                                            }}
                                                                        />
                                                                    </Customtooltip>
                                                                )}
                                                                {{
                                                                    asc: (
                                                                        <TableSortingIcon
                                                                            as={ArrowUp}
                                                                            style={{
                                                                                marginLeft: '10px'
                                                                            }}
                                                                        />
                                                                    ),
                                                                    desc: (
                                                                        <TableSortingIcon
                                                                            as={ArrowDown}
                                                                            style={{
                                                                                marginLeft: '10px'
                                                                            }}
                                                                        />
                                                                    )
                                                                }[header.column.getIsSorted() as string] ?? (
                                                                    <TableSortingUpAndDownIconContainer>
                                                                        <TableSortingIcon as={ArrowUp} />
                                                                        <TableSortingIcon as={ArrowDown} />
                                                                    </TableSortingUpAndDownIconContainer>
                                                                )}
                                                            </TableHeaderRowContainer>
                                                        ) : (
                                                            <TableHeaderRowContainer
                                                                center={+header.column.getCanSort()}
                                                            >
                                                                {flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                                {header.id === 'equityFixedIncome' && (
                                                                    <Customtooltip
                                                                        className="custom-tooltip"
                                                                        title={
                                                                            header.id === 'equityFixedIncome'
                                                                                ? t('TEXT_EQUITY_FIXED_INCOME_TOOLTIP')
                                                                                : ''
                                                                        }
                                                                        placement="top-start"
                                                                    >
                                                                        <ToolTipSvg
                                                                            style={{
                                                                                marginLeft: '10px'
                                                                            }}
                                                                        />
                                                                    </Customtooltip>
                                                                )}
                                                            </TableHeaderRowContainer>
                                                        )}
                                                    </StyledTableCell>
                                                )
                                            )}
                                        </TableRow>
                                    ))}
                            </TableHead>
                            <TableBody>
                                {table
                                    .getRowModel()
                                    .rows.map(
                                        (row: {
                                            id: React.Key | null | undefined;
                                            depth: any;
                                            getVisibleCells: () => any[];
                                        }) => (
                                            <StyledTableRow
                                                key={row.id}
                                                className={`table-expanded-depth-${row.depth}`}
                                            >
                                                {row.getVisibleCells().map(
                                                    (cell: {
                                                        id: React.Key | null | undefined;
                                                        column: {
                                                            columnDef: {
                                                                cell:
                                                                    | string
                                                                    | number
                                                                    | boolean
                                                                    | React.ReactElement<
                                                                          any,
                                                                          string | React.JSXElementConstructor<any>
                                                                      >
                                                                    | React.ReactFragment
                                                                    | React.ReactPortal
                                                                    | React.ComponentType<any>
                                                                    | null
                                                                    | undefined;
                                                            };
                                                        };
                                                        getContext: () => any;
                                                    }) => (
                                                        <StyledTableCell key={cell.id}>
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </StyledTableCell>
                                                    )
                                                )}
                                            </StyledTableRow>
                                        )
                                    )}
                            </TableBody>
                        </Table>
                        <Stack sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <PaginationLayout>
                                <Pagination paginationProps={paginationProps} table={table} options={[15, 20, 50]} />
                            </PaginationLayout>
                        </Stack>
                    </TableContainer>
                </>
            ) : (
                <NoResultsFoundContainer>
                    <Typography variant="h1" component="div">
                        {t(NO_RESULTS_FOUND)}
                    </Typography>
                </NoResultsFoundContainer>
            )}
        </StyledTableContainer>
    );
}

export default memo(DataTableOrganism);
